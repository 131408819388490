// @flow

import React, { Component, Fragment } from 'react';
import type { Node } from 'react';
import get from 'lodash/get';
import ClassNames from 'classnames';
import { Carousel } from '@riseart/antd-provider';
import { Button, Heading, MediaQuery, Paragraph, Link } from '@riseart/common';
import { Wrapper } from '@riseart/layout';
import { Banner, getBannerMedia } from 'shared_components/common/banner/Banner';
import { renderTextByLines } from 'shared_services/riseart/utils/Utils';

import {
  raScreenXsMax,
  raScreenSm,
  raScreenMdMax,
} from '@riseart/antd-provider/dist/website/variables.less';
import {
  heroCarouselCls,
  heroCarouselFadeCls,
  heroCls,
  heroCenterCls,
  heroRightCls,
  heroContentCls,
  heroContentBottomCls,
  heroTextCls,
  heroParagraphCls,
  heroBtnCls,
  heroBlackBtnCls,
} from 'shared_components/cms/modules/hero/Carousel.less';

type Props = {
  slides: Array<{
    align?: string,
    subTitle?: string,
    description?: string,
    button?: Object,
    title?: Array<Object>,
    media: Object,
    detail?: Object,
  }>,
};

type State = {
  isMounted: boolean,
};

/**
 * CmsModuleHeroCarousel
 */
export class CmsModuleHeroCarousel extends Component<Props, State> {
  /**
   * renderBanner
   *
   * @param {Object} props
   * @param {number | string} key
   * @returns {Node}
   */
  static renderBanner(
    { button, align, title = [], subTitle, description, detail, media = {} }: Object,
    key: number | string,
  ): Node {
    const DEFAULT_FONT_COLOR = 'white';
    const renderedTitle = title
      ? title.map((text, idx) => (
          <React.Fragment key={idx}>
            {idx ? <br /> : null}
            {text}
          </React.Fragment>
        ))
      : null;

    return (
      <Banner
        key={key}
        size="large"
        altTitle={title ? title.join(' ') : ''}
        artDirectionType="banner.fullWidth"
        artDirectionKey="common.banner"
        media={getBannerMedia(media, [])}
        className={ClassNames(
          heroCls,
          align === 'center' && heroCenterCls,
          align === 'right' && heroRightCls,
        )}
      >
        <MediaQuery maxWidth={raScreenXsMax}>
          {(isSmallScreen) => (
            <MediaQuery minWidth={raScreenSm} maxWidth={raScreenMdMax}>
              {(isMediumScreen) => {
                const fontColor = get(
                  media,
                  `${(isSmallScreen && 'small') || (isMediumScreen && 'medium') || 'large'}.font`,
                  DEFAULT_FONT_COLOR,
                );
                const colorStyle = { color: fontColor };

                return (
                  <Fragment>
                    {detail ? (
                      <div className={heroContentBottomCls}>
                        <Wrapper>
                          <Link
                            type="light"
                            to={detail.href}
                            external={!!detail.external}
                            title={detail.text}
                            {...(detail.target ? { target: detail.target } : {})}
                          >
                            {detail.text}
                          </Link>
                        </Wrapper>
                      </div>
                    ) : null}
                    <div className={heroContentCls}>
                      <Wrapper>
                        <div className={heroTextCls}>
                          <Heading tag="h2" level={isSmallScreen ? 2 : 1} style={colorStyle}>
                            {renderedTitle}
                          </Heading>
                          {subTitle ? (
                            <Heading tag="h2" level="3" style={colorStyle}>
                              {renderTextByLines(subTitle)}
                            </Heading>
                          ) : null}
                          {description ? (
                            <Paragraph className={heroParagraphCls} style={colorStyle}>
                              {renderTextByLines(description)}
                            </Paragraph>
                          ) : null}
                          {button ? (
                            <Button
                              type="ghost"
                              size="large"
                              href={button.href}
                              isAnchor
                              external={
                                [true, false].indexOf(button.external) > -1 ? button.external : true
                              }
                              className={ClassNames(
                                heroBtnCls,
                                fontColor === 'black' && heroBlackBtnCls,
                              )}
                            >
                              {button.text}
                            </Button>
                          ) : null}
                        </div>
                      </Wrapper>
                    </div>
                  </Fragment>
                );
              }}
            </MediaQuery>
          )}
        </MediaQuery>
      </Banner>
    );
  }

  /**
   * constructor
   *
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.state = { isMounted: false };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.setState({ isMounted: true });
  }

  /**
   * render
   *
   * @returns {Node}
   */
  render(): Node {
    const { slides } = this.props;
    const { isMounted } = this.state;
    const [firstSlide, ...restSlides] = slides;

    const firstBanner = firstSlide
      ? CmsModuleHeroCarousel.renderBanner(firstSlide, 'firstBanner')
      : null;

    // Initially the component will render only one banner, without carousel,
    // so that Google Core Web Vitals CLS metric is mmeasured with good value.
    // Once mounted (client side only) the banner will be replaced by the carousel with banners
    if (isMounted) {
      return (
        <Carousel
          speed={2000}
          autoplaySpeed={3000}
          effect="fade"
          autoplay
          className={ClassNames(heroCarouselCls, heroCarouselFadeCls)}
        >
          {firstBanner}
          {restSlides && restSlides.length
            ? restSlides.map(CmsModuleHeroCarousel.renderBanner)
            : null}
        </Carousel>
      );
    }
    return <div className={heroCarouselCls}>{firstBanner}</div>;
  }
}
